import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { useLocation } from '@reach/router';

// images
import ApplePodcastImage from '~images/quarantine/apple-podcasts.svg';
import GooglePodcastImage from '~images/quarantine/google-podcasts.svg';
import SpotifyImage from '~images/quarantine/spotify.svg';
import RssImage from '~images/quarantine/rss.svg';
import PodcastSubscriptionLink from '../PodcastSubscriptionsLink';

const PodcastSubscriptionLinks = ({ isQuarantine, ...rest }) => {
  const location = useLocation();
  const { pathname } = location;

  const isIntoTheVerse = pathname.includes('into-the-verse');

  return (
    <Box width="100%" {...rest}>
      <Grid container direction="row" justify="space-between" alignItems="center" spacing={1}>
        <Grid item xs={6} sm={isQuarantine ? 3 : 6} md={3}>
          <PodcastSubscriptionLink
            linkTo={
              isIntoTheVerse
                ? 'https://podcasts.apple.com/podcast/id1617334814'
                : 'https://podcasts.apple.com/us/podcast/aleph-beta-quarantined/id1502652985'
            }
            imageSrc={ApplePodcastImage}
            text="Apple"
          />
        </Grid>
        <Grid item xs={6} sm={isQuarantine ? 3 : 6} md={3}>
          <PodcastSubscriptionLink
            linkTo={
              isIntoTheVerse
                ? 'https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5idXp6c3Byb3V0LmNvbS8xOTYyMzc1LnJzcw=='
                : 'https://podcasts.google.com/?feed=aHR0cHM6Ly9mZWVkcy5idXp6c3Byb3V0LmNvbS85MjgyNjEucnNz'
            }
            imageSrc={GooglePodcastImage}
            text="Google"
          />
        </Grid>
        <Grid item xs={6} sm={isQuarantine ? 3 : 6} md={3}>
          <PodcastSubscriptionLink
            linkTo={
              isIntoTheVerse
                ? 'https://open.spotify.com/show/7Eb8eBQbAg1DehjgarSzas'
                : 'https://open.spotify.com/show/6yxyHkYndhvSPmxzwrtevn'
            }
            imageSrc={SpotifyImage}
            text="Spotify"
          />
        </Grid>
        <Grid item xs={6} sm={isQuarantine ? 3 : 6} md={3}>
          <PodcastSubscriptionLink
            linkTo={
              isIntoTheVerse
                ? 'https://feeds.buzzsprout.com/1962375.rss '
                : 'https://feeds.buzzsprout.com/928261'
            }
            imageSrc={RssImage}
            text="RSS Feed"
            isRss
          />
        </Grid>
      </Grid>
    </Box>
  );
};

PodcastSubscriptionLinks.propTypes = {
  isQuarantine: PropTypes.bool
};
PodcastSubscriptionLinks.defaultProps = {
  isQuarantine: false
};

export default PodcastSubscriptionLinks;
