import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

import Link from '~components/Link';

const useStyles = makeStyles(theme => ({
  wrapper: {
    width: '190px',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    gap: '10px',
    textDecoration: 'none !important',
    background: 'linear-gradient(180deg, rgba(255,255,255,1) 0%, #ecf6f9 50%)',
    padding: '8px 0',
    borderRadius: '10px',
    boxShadow: '0px 0px 6px 0px rgba(0,0,0,0.67)',
    transition: 'all .2s ease-in-out',
    '&:hover': {
      transform: 'scale(1.05)'
    },
    [theme.breakpoints.down('xs')]: {
      width: '170px'
    }
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
    color: '#444752',
    lineHeight: '1',
    fontWeight: '500'
  },
  smallSpan: {
    fontSize: '11px',
    opacity: 0.8
  },
  bigSpan: {
    fontSize: '21px'
  },
  link: {
    '& a': {
      color: 'transparent'
    }
  },
  media: {
    display: 'flex',
    maxWidth: '100%',
    marginLeft: '20px'
  }
}));

const PodcastSubscriptionsLink = ({ linkTo, imageSrc, text, isRss }) => {
  const classes = useStyles();

  return (
    <div className={classes.link}>
      <Link to={linkTo} target="_blank">
        <div className={classes.wrapper}>
          <img src={imageSrc} alt={text} className={classes.media} />
          <div className={classes.text}>
            <span className={classes.smallSpan}>{isRss ? 'Get the' : 'Listen on'}</span>
            <span className={classes.bigSpan}>{text}</span>
          </div>
        </div>
      </Link>
    </div>
  );
};

PodcastSubscriptionsLink.propTypes = {
  linkTo: PropTypes.string.isRequired,
  imageSrc: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  isRss: PropTypes.bool
};

PodcastSubscriptionsLink.defaultProps = {
  isRss: false
};

export default PodcastSubscriptionsLink;
